import { CompanySelect, MenuButton, MenuContainer } from './styled';
import { useHistory } from 'react-router-dom';

export const DesktopMenu = ({ companies, setOpenMyProfile, openMyProfile }) => {
  const { push } = useHistory();
  const logout = e => {
    e.preventDefault();

    push('/logout');
    return null;
  };

  const goToLeankeep = () => {
    window.location.href = 'https://app.leankeep.com/Anomalias/ShowAnomaliasTablePage.aspx';
  };
  return (
    <MenuContainer>
      <CompanySelect
        size="small"
        hasOnlyUnitsWithSubgroup={true}
        options={companies}
        defaultValue={companies?.length > 1 ? '' : companies[0]?.value}
        value={companies?.length > 1 ? '' : companies[0]?.value}
        isSearchDisabled={true}
        isClearInputActive={false}
        disabled={false}
      />
      <MenuButton Width={'32%'} onClick={goToLeankeep}>
        Acessar Leankeep
      </MenuButton>
      <MenuButton Width={'21%'} onClick={() => setOpenMyProfile(!openMyProfile)}>
        meu perfil
      </MenuButton>
      <MenuButton onClick={e => logout(e)} Width={'10%'}>
        sair
      </MenuButton>
    </MenuContainer>
  );
};
