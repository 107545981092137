import { useState, useEffect, useRef } from 'react';
import { CompanySelect, MenuContainer, DropdownButton, DropDownMenu, MenuItem } from './styled';
import { ReactComponent as DotsIcon } from 'assets/icons/3-dots.svg';
import { useHistory } from 'react-router-dom';
export const MenuMobile = ({ companies, setOpenMyProfile, openMyProfile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { push } = useHistory();
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const logout = e => {
    e.preventDefault();

    push('/logout');
    return null;
  };
  const handleOutsideClick = event => {
    if (
      (menuRef.current && !menuRef.current.contains(event.target)) ||
      (buttonRef.current && !buttonRef.current.contains(event.target))
    ) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);
  return (
    <MenuContainer>
      <CompanySelect
        size="small"
        hasOnlyUnitsWithSubgroup={true}
        options={companies}
        defaultValue={companies?.length > 1 ? '' : companies.value}
        value={companies?.length > 1 ? '' : companies[0]?.value}
        isSearchDisabled={true}
        isClearInputActive={false}
        disabled={false}
      />
      <DropdownButton onClick={() => setIsOpen(!isOpen)} ref={buttonRef}>
        <DotsIcon />
      </DropdownButton>
      <DropDownMenu isOpen={isOpen} ref={menuRef}>
        <MenuItem>Acessar leankeep</MenuItem>
        <MenuItem onClick={() => setOpenMyProfile(!openMyProfile)}>meu perfil</MenuItem>
        <MenuItem onClick={e => logout(e)}>sair</MenuItem>
      </DropDownMenu>
    </MenuContainer>
  );
};
