import { ModalGeneric } from 'components/ModalGeneric';
import { colors } from 'assets/styled/tokens';
import { StyledModalGenericBody, StyledModalGenericClose } from 'components/ModalGeneric/styled';
import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  box-shadow: 0px 1px 8px 0px #0000001a;
  background: #ffffff;
  width: 100%;
  min-height: ${props => props.height};
  border-radius: 8px;
  margin-top: 40px;
`;

export const Header = styled.div`
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #e5e5e5;
  padding: 24px;
  color: #f38a00;
  font-family: Blinker;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
`;

export const Content = styled.div`
  width: 100%;
  min-height: ${props => props.height};
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ProfileInputs = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  height: 32px;
  width: 280px;
  color: #888888;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
`;

export const SaveButton = styled.button`
  width: 78px;
  height: 32px;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #ffffff;
  background: #f38a00;
`;

export const ChangePasswordButton = styled.button`
  border: 1px solid #f38a00;
  width: 139px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 4px;
  color: #f38a00;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;
export const PasswordInstructions = styled.p`
  color: #888888;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
export const PasswordTitle = styled.h3`
  font-family: Blinker;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #f38a00;
`;
export const EmailInfo = styled.div`
  width: 280px;
  height: 56px;
  display: flex;
  flex-direction: row;

  background-color: #fce3c2;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #975600;
  border-radius: 4px;
  margin: 10px 0;
  padding: 12px;
  svg {
    margin-right: 10px;
    margin-top: 4px;
  }
`;
export const ResendEmailInfo = styled.div`
  width: 280px;
  height: 164px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fce3c2;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #975600;
  border-radius: 4px;
  margin: 10px 0;
  padding: 12px;
  div {
    display: flex;
    flex-direction: row;
    svg {
      margin-right: 10px;
      margin-top: 4px;
    }
  }
`;
export const ResendEmailButton = styled.button`
  border: 1px solid #e5e5e5;
  width: 233px;
  height: 32px;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;
export const EmailConfirmationModal = styled(ModalGeneric)`
  h6 {
    color: #f38a00;
    margin-bottom: 16px;
  }
  p {
    width: 80%;
    color: ${colors.gray};
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 19px;
  }

  ${StyledModalGenericClose} {
    margin-right: -8px;
  }
  ${StyledModalGenericBody} button {
    font-size: 14px;
    line-height: 16px;
    width: 96px;
  }
`;
