import React, { useState, useEffect, useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  ImageUploadContainer,
  //Input,
  Label,
  // SubLabel,
  PreviewImage,
  ButtonContainer,
  Button,
  //ButtonTrash,
  InputLabel,
  Wrapper,
  SignModal,
  ButtonClear,
  TransparentBg,
  ModalHeader,
  Line,
  TextAndLine,
} from './styled';
import { RegistrationService } from 'services/main/endpoints/RegistrationService';
import { ReactComponent as SignatureIcon } from 'assets/icons/signature_40px.svg';
import { ReactComponent as CleanSignIcon } from 'assets/icons/X-white.svg';
import SignatureCanvas from 'react-signature-canvas';
import { useCookies } from 'react-cookie';
import * as Sentry from '@sentry/react';
import { createSuccessToast } from 'utils/createToast';
const ImageUpload = ({ inputLabel, inputName, setToastList }) => {
  const [cookie] = useCookies();
  const { control, setValue, watch } = useFormContext();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [openSignModal, setOpenSignModal] = useState(false);
  const signatureCanvas = useRef({});
  const signature = watch(inputName);
  const getNewPath = async empresaId => {
    try {
      const usuarioId = cookie.chamadosAccess.userId;
      const response = await RegistrationService.getImagePath({ EmpresaId: empresaId, usuarioId });
      if (response.status === 200) {
        return response.data.assinaturaNewPath;
      }
    } catch (error) {
      console.error('Erro ao obter o novo caminho de mídia:', error);
      Sentry.captureException(error);
    }
  };
  const uploadImage = async (mediaPath, file) => {
    try {
      const token = cookie.authToken;
      const response = await RegistrationService.uploadSignature({ filePath: mediaPath, file, token });
      return response.status === 200;
    } catch (error) {
      console.error('Erro ao fazer o upload:', error);
      Sentry.captureException(error);
      return false;
    }
  };

  const handleImageUpload = async (imageData, mediaType, empresaId) => {
    if (imageData && typeof imageData !== 'string') {
      const file = imageData;
      const fileExtension = file.name.split('.').pop();
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
        createErrorToast(setToastList, `Formato de arquivo não suportado.`);
        return;
      }

      try {
        const mediaPath = await getNewPath(empresaId, mediaType, fileExtension);
        const uploadSuccess = await uploadImage(mediaPath, file);
        if (uploadSuccess) {
          createSuccessToast(setToastList, 'Assinatura adicionada');
          return mediaPath.replace('/uploads/', '');
        } else {
          throw new Error(`Erro ao fazer upload da imagem.`);
        }
      } catch (error) {
        console.error(error.message);
        Sentry.captureMessage(error.message);
        return;
      }
    }
    return imageData;
  };
  useEffect(() => {
    if (signature) {
      setPreviewUrl(signature);
    } else {
      setPreviewUrl(null);
    }
  }, [signature, inputName]);
  const handleClear = () => {
    signatureCanvas.current.clear();
    setPreviewUrl(null);
    setValue(inputName, null);
  };
  const base64ToPng = base64Url => {
    let file;
    if (typeof base64Url === 'string' && base64Url.startsWith('data:image/')) {
      const base64WithoutPrefix = base64Url.split(',')[1];
      const binaryData = Uint8Array.from(atob(base64WithoutPrefix), char => char.charCodeAt(0));
      const blob = new Blob([binaryData], { type: 'image/png' });
      file = new File([blob], 'signature.png', { type: 'image/png' });
    } else if (base64Url instanceof Blob) {
      file = new File([base64Url], 'signature.png', { type: 'image/png' });
    } else {
      return base64Url;
    }
    return file;
  };
  const handleSave = async () => {
    const trimmedCanvas = signatureCanvas.current.getTrimmedCanvas();
    const previewImageUrl = trimmedCanvas.toDataURL('image/png');
    const file = base64ToPng(previewImageUrl);
    const empresaId = cookie.companies?.id;
    const imageUrl = await handleImageUpload(file, 'Empresas', empresaId);
    setPreviewUrl(imageUrl);
    setOpenSignModal(false);
  };
  const handleClearPreview = () => {
    setPreviewUrl(null);
    setValue(inputName, null);
  };

  return (
    <Controller
      name={inputName}
      control={control}
      defaultValue={null}
      render={() => (
        <Wrapper>
          <InputLabel>{inputLabel}</InputLabel>
          <ImageUploadContainer borderDashed={false} onClick={() => setOpenSignModal(true)}>
            {!previewUrl && (
              <Label htmlFor={`fileInput-${inputName}`}>
                <SignatureIcon />
                Assinar manualmente
              </Label>
            )}
            <PreviewImage
              src={process.env.REACT_APP_SIGNATURE_IMAGE + 'v1/static/' + previewUrl}
              alt="Preview da Imagem"
              previewUrl={previewUrl}
            />
          </ImageUploadContainer>
          {openSignModal && (
            <TransparentBg>
              <SignModal>
                <ModalHeader>Adicionar Assinatura</ModalHeader>
                <SignatureCanvas
                  ref={signatureCanvas}
                  penColor="black"
                  canvasProps={{ width: 480, height: 240, className: 'sigCanvas' }}
                />
                <TextAndLine>
                  <Line />
                  <div>Assine em cima da linha</div>
                </TextAndLine>
                <ButtonContainer>
                  <Button type="button" onClick={handleClear}>
                    Limpar
                  </Button>
                  <Button type="button" onClick={() => handleSave()}>
                    Salvar
                  </Button>
                </ButtonContainer>
              </SignModal>
            </TransparentBg>
          )}
          {previewUrl && (
            <ButtonClear type="button" onClick={handleClearPreview}>
              <CleanSignIcon />
            </ButtonClear>
          )}
        </Wrapper>
      )}
    />
  );
};

export default ImageUpload;
