import React, { useEffect, useState } from 'react';
import {
  ChangePasswordButton,
  Content,
  EmailConfirmationModal,
  EmailInfo,
  Header,
  PasswordInstructions,
  PasswordTitle,
  ProfileInputs,
  ProfileWrapper,
  ResendEmailButton,
  ResendEmailInfo,
  SaveButton,
} from './styled';
import { Button } from 'components/Button';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { FieldRow } from 'components/Form/subcomponents/FieldRow';
import { Input } from './components/Input';
import ImageUpload from './components/ImageUpload';
import { RegistrationService } from 'services/main/endpoints/RegistrationService';
import { useCookies } from 'react-cookie';
import { Toast } from 'components/Toast';
import {
  createEmailConfirmationToast,
  createErrorToast,
  createGenericErrorToast,
  createSuccessToast,
} from 'utils/createToast';
import * as Sentry from '@sentry/react';
import { AuthService } from 'services/main';
import { ReactComponent as YellowWarningIcon } from 'assets/icons/yellow-warning.svg';
import { ReactComponent as EmailWarningIcon } from 'assets/icons/email-warning.svg';
export const CustumerProfile = () => {
  const [openEmailConfirmationModal, setOpenEmailConfirmationModal] = useState(false);
  const [openPasswordChanger, setOpenPasswordChanger] = useState(false);
  const [cookie] = useCookies();
  const [toastList, setToastList] = useState([]);
  const methods = useForm({
    defaultValues: {
      login: '',
      nome: '',
      password: '',
      email: '',
      telefone: '',
      assinatura: null,
    },
  });
  const { control, reset, handleSubmit } = methods;
  const [isEmailValid, setIsEmailValid] = useState(true);
  const getUserInfo = async () => {
    const EmpresaId = cookie.companies?.id;
    const userId = cookie.chamadosAccess.userId;
    const response = await RegistrationService.getChamadoUserInfo({ EmpresaId, id: userId });
    setIsEmailValid(response.data?.emailValido);
    reset({
      login: response.data?.login || '',
      nome: response.data?.nome || '',
      password: response.data?.senha || '',
      email: response.data?.email || '',
      telefone: response.data?.celular || '',
      assinatura: response.data?.assinaturaPath ? response.data?.assinaturaPath || null : null,
    });
  };
  useEffect(() => {
    getUserInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const saveData = async data => {
    const EmpresaId = cookie.companies?.id;
    const forceEmailConfirmation = false;
    try {
      const response = await RegistrationService.saveChamadoUserInfo({
        EmpresaId,
        nome: data?.nome,
        email: data?.email,
        celular: data?.telefone,
        forceEmailConfirmation,
      });

      if (response.data.success) {
        setOpenEmailConfirmationModal(true);
        createSuccessToast(setToastList, response.data.message);
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };
  const reSendData = async data => {
    const EmpresaId = cookie.companies?.id;
    const forceEmailConfirmation = !isEmailValid;
    try {
      const response = await RegistrationService.saveChamadoUserInfo({
        EmpresaId,
        nome: data?.nome,
        email: data?.email,
        celular: data?.telefone,
        forceEmailConfirmation,
      });

      if (response.data.success) {
        createEmailConfirmationToast(setToastList, 'E-mail enviado para o endereço cadastrado.');
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };
  const checkNewPassword = (novaSenha, confirmacaoSenha) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,20}$/;
    if (!regex.test(novaSenha)) {
      createErrorToast(
        setToastList,
        'A nova senha deve ter de 6 a 20 caracteres, com pelo menos uma letra e um número.',
      );
      return false;
    }
    if (novaSenha !== confirmacaoSenha) {
      createErrorToast(setToastList, 'As senhas não conferem.');
      return false;
    }
    return true;
  };
  const hanldePasswordChange = async data => {
    const newPasswordIsValid = checkNewPassword(data.newPassword, data.confirmPassword);
    if (newPasswordIsValid) {
      try {
        const response = await AuthService.newPasswordChamado({
          oldPassword: data.currentPassword,
          password: data.newPassword,
        });
        if (response.status === 200) {
          setOpenPasswordChanger(false);
          createSuccessToast(setToastList, 'Senha alterada com sucesso');
          return;
        }
      } catch (error) {
        if (error.response.status === 404) {
          createErrorToast(setToastList, 'A senha antiga está incorreta.');
        } else {
          createGenericErrorToast(setToastList);
        }
        Sentry.captureException(error);
      }
    }
    return;
  };
  return (
    <ProfileWrapper height={openPasswordChanger ? '470px' : '680px'}>
      <Header>Meu Perfil</Header>
      <Content height={openPasswordChanger ? '380px' : '603px'}>
        <FormProvider {...methods}>
          {openPasswordChanger && (
            <FieldRow>
              <PasswordTitle>Alterar Senha</PasswordTitle>
              <FieldRow marginBottom="16px" />
              <FormGroup label={'Senha atual'}>
                <Controller
                  name="currentPassword"
                  render={props => <Input {...props} type="password" isPassword />}
                  control={control}
                  defaultValue={''}
                />
              </FormGroup>
              <FieldRow marginBottom="16px" />
              <PasswordInstructions>
                A nova senha deve ter de 6 a 20 caracteres,
                <br /> com pelo menos uma letra e um número.
              </PasswordInstructions>
              <FieldRow marginBottom="16px" />
              <FormGroup label={'Nova Senha'}>
                <Controller
                  name="newPassword"
                  render={props => <Input {...props} type="password" isPassword />}
                  control={control}
                  defaultValue={''}
                />
              </FormGroup>
              <FieldRow marginBottom="16px" />
              <FormGroup label={'Confirmar senha'}>
                <Controller
                  name="confirmPassword"
                  render={props => <Input {...props} type="password" isPassword />}
                  control={control}
                  defaultValue={''}
                />
              </FormGroup>
              <FieldRow marginBottom="16px" />
              <FormGroup>
                <SaveButton onClick={handleSubmit(hanldePasswordChange)}>Salvar</SaveButton>
              </FormGroup>
            </FieldRow>
          )}
          {!openPasswordChanger && (
            <FieldRow>
              <FormGroup label={'Nome'} disabled>
                <Controller
                  name="nome"
                  render={props => <ProfileInputs {...props} disabled />}
                  defaultValue={''}
                  control={control}
                />
              </FormGroup>
              <FieldRow marginBottom="16px" />
              <FormGroup label={'Login'} disabled={true}>
                <Controller
                  name="login"
                  render={props => <ProfileInputs {...props} disabled />}
                  control={control}
                  disabled={true}
                  defaultValue={''}
                />
              </FormGroup>
              <FieldRow marginBottom="16px" />

              <FormGroup label={'Senha'}>
                {/* <Controller
                  name="password"
                  render={props => <Input {...props} type="password" isPassword />}
                  control={control}
                /> */}
                <ChangePasswordButton onClick={() => setOpenPasswordChanger(!openPasswordChanger)}>
                  ALTERAR SENHA
                </ChangePasswordButton>
              </FormGroup>
              <FieldRow marginBottom="16px" />
              <FormGroup label={'Email'}>
                {isEmailValid && (
                  <EmailInfo>
                    <YellowWarningIcon />
                    Cadastre um e-mail para receber
                    <br />
                    atualizações sobre seus chamados.
                  </EmailInfo>
                )}
                <Controller
                  name="email"
                  render={props => <ProfileInputs {...props} />}
                  defaultValue={''}
                  control={control}
                />
                {!isEmailValid && (
                  <ResendEmailInfo>
                    <div>
                      <YellowWarningIcon />
                      Confirme seu e-mail para receber
                      <br />
                      avisos sobre o andamento dos seus
                      <br />
                      chamados. Verifique a mensagem
                      <br />
                      que enviamos para o endereço
                      <br />
                      cadastrado.
                    </div>
                    <ResendEmailButton onClick={handleSubmit(reSendData)}>
                      Reenviar e-mail de confirmação
                    </ResendEmailButton>
                  </ResendEmailInfo>
                )}
              </FormGroup>
              <FieldRow marginBottom="16px" />
              <FormGroup label={'Telefone'}>
                <Controller
                  name="telefone"
                  render={props => <ProfileInputs {...props} />}
                  defaultValue={''}
                  control={control}
                />
              </FormGroup>
              <FieldRow marginBottom="16px" />
              <FormGroup label={'Assinatura'}>
                <ImageUpload inputName={'assinatura'} inputLabel={''} setToastList={setToastList} />
              </FormGroup>
              <FieldRow marginBottom="16px" />
              <FormGroup>
                <SaveButton onClick={handleSubmit(saveData)}>Salvar</SaveButton>
              </FormGroup>
            </FieldRow>
          )}
        </FormProvider>
        <EmailConfirmationModal
          hasHeader
          title="Confirme seu e-mail"
          icon={<EmailWarningIcon />}
          size={{ width: '443px' }}
          open={openEmailConfirmationModal}
          closeModal={() => setOpenEmailConfirmationModal(false)}
        >
          <p>
            Confirme seu e-mail para receber avisos sobre o andamento dos seus chamados. Verifique a mensagem que
            enviamos para o endereço cadastrado.
          </p>
          <Button theme="primary" onClick={() => setOpenEmailConfirmationModal(false)}>
            Entendi
          </Button>
        </EmailConfirmationModal>
      </Content>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
    </ProfileWrapper>
  );
};
