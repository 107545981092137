const createToast = ({ type, message, btnTitle, onClick }) => {
  return {
    id: Math.floor(Math.random() * 1000),
    type,
    message,
    btnTitle,
    onClick,
  };
};
export const createEmailConfirmationToast = (setToastList, message) => {
  setToastList(prevState => [
    ...prevState,
    createToast({
      type: 'emailConfirmation',

      message: message,
    }),
  ]);
};
export const createSuccessToast = (setToastList, message) => {
  setToastList(prevState => [
    ...prevState,
    createToast({
      type: 'success',

      message: message,
    }),
  ]);
};

export const createErrorToast = (setToastList, message) => {
  setToastList(prevState => [
    ...prevState,
    createToast({
      type: 'error',

      message: message,
    }),
  ]);
};

export const createTrashToast = (setToastList, message) => {
  setToastList(prevState => [
    ...prevState,
    createToast({
      type: 'trash',

      message: message,
    }),
  ]);
};
export const createGenericErrorToast = setToastList => {
  setToastList(prevState => [
    ...prevState,
    createToast({
      type: 'error',

      message: 'Ocorreu um erro. Tente novamente.',
    }),
  ]);
};
export const createDarkSuccessToast = (setToastList, message) => {
  setToastList(prevState => [
    ...prevState,
    createToast({
      type: 'darkSuccess',

      message: message,
    }),
  ]);
};

export { createToast };
