import { medias } from 'assets/styled/tokens';
import styled from 'styled-components';

export const ImageUploadContainer = styled.div`
  position: relative;
  max-width: 468px;
  width: 288px;
  height: 110px;
  border: ${props => (props.borderDashed ? '1px dashed #cccccc' : '1px solid #cccccc')};
  border-radius: 3px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Wrapper = styled.div`
  //margin: 0 10px;
  position: relative;
`;
export const InputLabel = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #1e1e1e;
`;
export const Input = styled.input`
  display: none;
`;

export const Label = styled.label`
  font-family: Roboto;
  position: absolute;
  color: #444444;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 13px;
`;

export const SubLabel = styled.span`
  font-weight: normal;
  display: block;
  cursor: pointer;
  font-size: 12px;
  color: #888888;
`;

export const PreviewImage = styled.img`
  width: 292px;
  height: 106px;
  display: ${({ previewUrl }) => (previewUrl ? 'block' : 'none')};
`;
export const ButtonContainer = styled.div`
  width: 100%;
  height: 64px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
`;

export const ButtonTrash = styled.button`
  background-color: #fff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 5px;
  border-radius: 3px;
`;
export const Button = styled.button`
  background-color: #f38a00;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 5px;
  border-radius: 3px;
  height: 30px;
`;
export const ButtonClear = styled.button`
  background-color: #f38a00;
  color: white;
  border: none;
  //padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  //margin-left: 5px;
  border-radius: 4px;
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
`;

export const SignModal = styled.div`
  width: 375px;
  height: 368px;
  border: 1px solid #cccccc;

  background-color: #eae8e5;
  z-index: 5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${medias.tablet}) {
    width: 480px;
    height: 368px;
  }
`;
export const TransparentBg = styled.div`
  background: rgba(0, 0, 0, 0.7);
  //opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px 0;
  z-index: 5;
`;
export const ModalHeader = styled.div`
  width: 100%;
  height: 64px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Blinker;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  align-items: center;
  background-color: #ffffff;
`;
export const TextAndLine = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
`;
export const Line = styled.div`
  border-top: 1px solid black;
  width: 300px;
  margin: 5px auto;
`;
