import { CustomerPortalHeader } from 'components/Header/CustomerPortalHeader';
import {
  CleanFiltersButton,
  Content,
  ContentHeader,
  FiltersButton,
  OpenCallButton,
  Page,
  RadioFormGroup,
  SearchAndFilterContainer,
  StyledContainerHeader,
  StyledContainerSearchBar,
  StyledFormGroup,
  StyledFormRadio,
  StyledInnerGridColumn,
  TablecontainerWrapper,
  TableTitle,
  StyledContainerFooter,
  LeftContainerFooter,
  RightContainerFooter,
  Paginate,
  StyledDateRangePicker,
  MobileCleanFilterDiv,
  FooterContainer,
  NoOcurrenceScreen,
  ReloadButton,
} from './styled';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-icon.svg';
import { FieldRow } from 'components/Form/subcomponents/FieldRow';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as CloseFilterIcon } from 'assets/icons/close-filter.svg';
import { ReactComponent as CleanFilterIcon } from 'assets/icons/cleanFiltersIcon.svg';
import { ReactComponent as LogoBlack } from 'assets/icons/logo-horizontal-black.svg';
import { ReactComponent as NotFoundIcon } from 'assets/icons/404-icon.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/loading-o-icon.svg';
import { Select } from 'components/Tables/ScheduleTable/Form/subcomponents/Select';
import { useState, useMemo, useEffect, useRef } from 'react';
import { Radio } from 'components/Form/subcomponents/Radio';
import CustomerPortalTable from 'components/Tables/CustomerPortalTable';
import { useMediaQuery } from 'react-responsive';
import { MobileCustomerPortalTable } from 'components/Tables/CustomerPortalTable/Mobile';
import { useCookies } from 'react-cookie';
import { ChamadoService } from 'services/main/endpoints/ChamadoService';
import { subMonths, format } from 'date-fns';
import { createGenericErrorToast, createErrorToast } from 'utils/createToast';
import { Toast } from 'components';
import { DateRangePicker } from 'components/Form/subcomponents/MobileDateRangePicker';
import { FiltersService } from 'services/reports';
import { useDebounce } from 'hooks';
import { CustumerProfile } from 'components/CustomerProfile';
export const CustomerPortal = () => {
  const [isFirstMount, setIsFirstMount] = useState(true);
  const [cookie] = useCookies();
  const [openMyProfile, setOpenMyProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [occurrenceNumber, setOccurrenceNumber] = useState(0);
  const [toastList, setToastList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [pageNumber, setPageNumber] = useState(1);
  const [itensPerPage, setItensPerPage] = useState(5);
  const pageOptions = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];
  const [filters, setFilters] = useState({
    unidade: [],
    status: [],
    ambiente: [],
    equipamento: [],
  });
  const initialPeriod = [subMonths(new Date(), 3), subMonths(new Date(), 0)];
  const {
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: {
      chamadosType: 'meus',
      unidade: '',
      status: '',
      ambiente: '',
      equipamento: '',
      period: initialPeriod,
      pageItens: pageOptions[0].value,
    },
  });
  const period = watch('period');
  const [filterIsOpen, setFilterIsOpen] = useState(cookie?.qrcodeFilters ? true : false);
  const statusOptions = [
    {
      label: 'Analisada',
      value: 1,
    },
    { label: 'Solucionada', value: 2 },
    { label: 'Não Analisada', value: 3 },
  ];
  const columns = useMemo(
    () => [
      { Header: 'Data/Hora de Abertura', accessor: 'data', width: 100 },
      { Header: 'Local / Equipamento', accessor: 'local', width: 300 },
      {
        Header: 'Nº Chamado / Descrição',
        accessor: 'chamado',
        width: 560,
      },
      { Header: 'Status', accessor: 'status', width: 240 },
    ],
    [],
  );
  const getDate = data => {
    const dateString = new Date(data);
    const date = dateString.toLocaleDateString();
    const time = dateString.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return (
      <div>
        <div>{date}</div> <div>{time}</div>
      </div>
    );
  };
  const populateTableStrategy = async search => {
    const UnidadeId = getValues('unidade');
    const AreaId = getValues('ambiente');
    const EquipamentoId = getValues('equipamento');
    const StatusIds = getValues('status');
    const chamados = getValues('chamadosType') || 'meus';
    const result =
      typeof period === 'string'
        ? period?.split('/')
        : `${format(initialPeriod[0], 'yyyy-MM-dd')}/${format(initialPeriod[1], 'yyyy-MM-dd')}`?.split('/');
    const response = await ChamadoService.getOccurences({
      dataInicio: result[0],
      dataFim: result[1],
      pageSize: itensPerPage || 5,
      pageIndex: pageNumber,
      empresa: cookie?.companies?.id,
      UnidadeId,
      AreaId,
      EquipamentoId,
      Search: search,
      StatusIds,
      SolicitanteId: chamados === 'meus' ? cookie.chamadosAccess.userId : '',
    });
    return response?.data?.map(data => ({
      data: getDate(data.dataRegistro),
      status: data?.tipoUltimaCorrecao,
      chamado: data?.descricao,
      local: getLocation(data?.areaNome, data?.equipamentoNome, data?.siteNome),
      text: data?.statusNome,
      anomaliaGid: data?.anomaliaGid,
      cqa: data?.cqa,
    }));
  };
  const populateFilters = async () => {
    return await Promise.all([
      FiltersService.getUnits({ companyId: cookie?.companies?.id }),
      ChamadoService.getAreas({ empresa: cookie?.companies?.id }),
      ChamadoService.getEquipments({ empresa: cookie?.companies?.id, SiteId: getValues('unidade') }),
      //FiltersService.getStates({ companyId: cookie?.companies?.id }),
    ]).then(values => ({
      unidade: values[0]?.data.map(({ nome, site }) => ({
        label: nome,
        value: site,
      })),
      ambiente: values[1]?.data.map(({ nomeFormatted, gid }) => ({
        label: nomeFormatted,
        value: gid,
      })),
      equipamento: values[2]?.data.map(({ gid, nomeFormatted }) => ({
        label: nomeFormatted,
        value: gid,
      })),
    }));
  };
  const getLocation = (areaNome, equipamentoNome, siteNome) => {
    return (
      <>
        {siteNome && (
          <div>
            <b>Unidade:</b> {siteNome}
          </div>
        )}

        {areaNome && (
          <div>
            <b>Área:</b> {areaNome}
          </div>
        )}

        {equipamentoNome && (
          <div>
            <b>Equipamento:</b> {equipamentoNome}
          </div>
        )}
      </>
    );
  };

  const populateFields = async search => {
    try {
      const tableData = await populateTableStrategy(search);
      const filters = await populateFilters();
      tableData?.plans?.reverse();
      setFields(tableData);
      setFilters(filters);
    } catch (err) {
      createGenericErrorToast(setToastList);
    }
  };
  const fetchData = async search => {
    try {
      setLoading(true);
      await populateFields(search);
    } catch (err) {
      createGenericErrorToast(setToastList);
    } finally {
      setLoading(false);
    }
    if (!cookie?.companies?.id) {
      createErrorToast(setToastList, 'Selecione uma empresa antes de continuar');
      return;
    }
  };

  const fetchOccurNumber = async search => {
    try {
      const UnidadeId = getValues('unidade');
      const AreaId = getValues('ambiente');
      const EquipamentoId = getValues('equipamento');
      const StatusIds = getValues('status');
      const chamados = getValues('chamadosType') || 'meus';
      const date =
        typeof period === 'string'
          ? period?.split('/')
          : `${format(initialPeriod[0], 'yyyy-MM-dd')}/${format(initialPeriod[1], 'yyyy-MM-dd')}`?.split('/');
      const result = await ChamadoService.getOcurrencesNumber({
        dataInicio: date[0],
        dataFim: date[1],
        empresa: cookie?.companies?.id,
        UnidadeId,
        AreaId,
        EquipamentoId,
        Search: search,
        StatusIds,
        SolicitanteId: chamados === 'meus' ? cookie.chamadosAccess.userId : '',
      });
      setOccurrenceNumber(result.data);
    } catch {
      createGenericErrorToast(setToastList);
    }
  };
  //const filtersChanged = !!watch('unidade') || !!watch('ambiente') || !!watch('equipamento') || !!watch('status');

  useEffect(() => {
    if (cookie?.qrcodeFilters?.site || cookie?.qrcodeFilters?.equipamento || cookie?.qrcodeFilters?.ambiente) {
      reset({
        chamadosType: 'meus',
        unidade: cookie.qrcodeFilters.site ? cookie.qrcodeFilters.site?.value : '',
        status: '',
        ambiente: cookie.qrcodeFilters.site ? cookie.qrcodeFilters.ambiente?.value : '',
        equipamento: cookie.qrcodeFilters.equipamento ? cookie.qrcodeFilters.equipamento?.value : '',
        period: initialPeriod,
        pageItens: pageOptions[0].value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie, reset]);
  const dependenceArray = [period, cookie?.companies?.id, debouncedSearchTerm, watch('unidade'), watch('chamadosType')];
  useEffect(() => {
    if (cookie?.companies?.id) {
      fetchOccurNumber(debouncedSearchTerm);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenceArray);
  useEffect(() => {
    if (cookie?.companies?.id) {
      if (occurrenceNumber > 0) {
        fetchData(debouncedSearchTerm);
        setIsFirstMount(false);
        getItensPerPage();
      }
    }

    // eslint-disable-next-line
  }, [errors, itensPerPage, pageNumber, period, cookie?.companies?.id, occurrenceNumber]);

  const [fields, setFields] = useState([]);
  const getItensPerPage = () => {
    setItensPerPage(getValues('pageItens'));
  };
  const pageCount1 = Math.ceil(occurrenceNumber / itensPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  };
  const isDesktop = useMediaQuery({ minWidth: 1081 });
  const handleSearch = event => {
    const query = event.target.value;
    setSearchTerm(query);
  };
  const searchInputRef = useRef(null);
  const cleanFilters = () => {
    setValue('unidade', '');
    setValue('status', '');
    setValue('equipamento', '');
    setValue('ambiente', '');
    setSearchTerm('');
    fetchOccurNumber(debouncedSearchTerm);
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  };
  return (
    <Page>
      <CustomerPortalHeader setOpenMyProfile={setOpenMyProfile} openMyProfile={openMyProfile} />
      <Content>
        {openMyProfile && <CustumerProfile />}
        {!openMyProfile && (
          <>
            <ContentHeader>
              <b>Boas vindas,</b> Aqui você pode Abrir um Chamado e acompanhar seu histórico de Chamados.
            </ContentHeader>

            <TablecontainerWrapper>
              {isDesktop && (
                <>
                  <StyledContainerHeader>
                    <TableTitle>Histórico de Chamados</TableTitle>
                    <OpenCallButton>
                      <PlusIcon />
                      ABRIR UM CHAMADO
                    </OpenCallButton>
                  </StyledContainerHeader>
                  <FieldRow marginBottom="1rem">
                    <StyledInnerGridColumn size={2} columnGap="1rem">
                      <StyledFormGroup label={'Data de Abertura'} isRequired width={'38%'} inputSize={'85%'}>
                        <Controller
                          render={props => (
                            <StyledDateRangePicker
                              {...props}
                              data-cy="opening-of-occurrence-date-picker"
                              size="small"
                              placeholder="Seleção obrigatória"
                              required={false}
                              notClean={true}
                              value={`${format(initialPeriod[0], 'yyyy-MM-dd')}/${format(
                                initialPeriod[1],
                                'yyyy-MM-dd',
                              )}`}
                            />
                          )}
                          name="period"
                          control={control}
                        />
                      </StyledFormGroup>
                      <SearchAndFilterContainer>
                        <StyledContainerSearchBar
                          placeholder={'Pesquisar Nº Chamado / Descrição'}
                          onChange={handleSearch}
                          ref={searchInputRef}
                        />
                        {filterIsOpen && (
                          <CleanFiltersButton onClick={cleanFilters}>
                            <CleanFilterIcon />
                            LIMPAR FILTROS
                          </CleanFiltersButton>
                        )}
                        <FiltersButton onClick={() => setFilterIsOpen(!filterIsOpen)}>
                          {filterIsOpen === true ? <CloseFilterIcon /> : <FilterIcon />}
                          FILTROS
                        </FiltersButton>

                        <ReloadButton onClick={fetchData}>
                          <ReloadIcon />
                          ATUALIZAR LISTA
                        </ReloadButton>
                      </SearchAndFilterContainer>
                    </StyledInnerGridColumn>
                    {filterIsOpen && (
                      <>
                        <StyledInnerGridColumn size={1}>
                          <StyledFormGroup label={'Unidade'} width={'23%'} inputSize={'100%'}>
                            <Controller
                              name="unidade"
                              control={control}
                              //defaultValue={''}
                              render={props => (
                                <Select
                                  {...props}
                                  size="small"
                                  inputDifferent={true}
                                  placeholder="Seleção Opcional"
                                  options={filters?.unidade}
                                  // onInputChange={handleChange}
                                  onChange={value => {
                                    //handleChange(value);
                                    props.onChange(value);
                                    if (value === '') {
                                      setValue('equipamento', '');
                                      setValue('ambiente', '');
                                    }
                                    fetchOccurNumber(debouncedSearchTerm);
                                  }}
                                  ref={null}
                                />
                              )}
                            />
                          </StyledFormGroup>
                          <StyledFormGroup
                            label={'Ambiente'}
                            width={'23%'}
                            inputSize={'100%'}
                            disabled={!watch('unidade')}
                          >
                            <Controller
                              name="ambiente"
                              control={control}
                              //defaultValue={fields?.companie?.cidade}
                              render={props => (
                                <Select
                                  {...props}
                                  size="small"
                                  hasTooltip={true}
                                  tooltipText="Selecione uma Unidade para ver a lista de Ambientes."
                                  inputDifferent={true}
                                  placeholder="Seleção Opcional"
                                  options={filters?.ambiente}
                                  disabled={!watch('unidade')}
                                  // onInputChange={handleChange}
                                  onChange={value => {
                                    //handleChange(value);
                                    props.onChange(value);
                                    //fetchData();
                                    fetchOccurNumber(debouncedSearchTerm);
                                  }}
                                  ref={null}
                                />
                              )}
                            />
                          </StyledFormGroup>
                          <StyledFormGroup
                            label={'Equipamento'}
                            width={'23%'}
                            inputSize={'100%'}
                            disabled={!watch('unidade')}
                          >
                            <Controller
                              name="equipamento"
                              control={control}
                              //defaultValue={fields?.companie?.cidade}
                              render={props => (
                                <Select
                                  {...props}
                                  size="small"
                                  hasTooltip={true}
                                  tooltipText="Selecione uma Unidade para ver a lista de Ambientes."
                                  inputDifferent={true}
                                  disabled={!watch('unidade')}
                                  placeholder="Seleção Opcional"
                                  options={filters?.equipamento}
                                  // onInputChange={handleChange}
                                  onChange={value => {
                                    //handleChange(value);
                                    props.onChange(value);
                                    //fetchData();
                                    fetchOccurNumber(debouncedSearchTerm);
                                  }}
                                  ref={null}
                                />
                              )}
                            />
                          </StyledFormGroup>
                          <StyledFormGroup label={'Status'} width={'23%'} inputSize={'100%'}>
                            <Controller
                              name="status"
                              control={control}
                              //defaultValue={fields?.companie?.cidade}
                              render={props => (
                                <Select
                                  {...props}
                                  size="small"
                                  inputDifferent={true}
                                  placeholder="Seleção Opcional"
                                  options={statusOptions}
                                  // onInputChange={handleChange}
                                  onChange={value => {
                                    //handleChange(value);
                                    props.onChange(value);
                                    fetchOccurNumber(debouncedSearchTerm);
                                    //fetchData();
                                  }}
                                  ref={null}
                                />
                              )}
                            />
                          </StyledFormGroup>
                        </StyledInnerGridColumn>
                        <StyledInnerGridColumn size={1}>
                          <RadioFormGroup label={'Exibir:'}>
                            <StyledFormRadio>
                              <Radio
                                text="Chamados que eu abri"
                                type="radio"
                                id="meusChamados"
                                name="chamadosType"
                                value="meus"
                                ref={register()}
                              />
                              <Radio
                                disabled={cookie?.chamadosAccess?.chamado}
                                text="Todos os chamados"
                                type="radio"
                                id="todosChamados"
                                name="chamadosType"
                                value="todos"
                                ref={register()}
                              />
                            </StyledFormRadio>
                          </RadioFormGroup>
                        </StyledInnerGridColumn>
                      </>
                    )}
                  </FieldRow>
                  {occurrenceNumber === 0 ? (
                    <NoOcurrenceScreen>
                      <NotFoundIcon />
                      <p>Nenhum chamado foi encontrado.</p>
                    </NoOcurrenceScreen>
                  ) : (
                    <>
                      <CustomerPortalTable columns={columns} data={fields} loading={loading} />
                      <StyledContainerFooter>
                        <LeftContainerFooter>
                          <h3>Itens por página:</h3>
                          <FieldRow marginSides="16px">
                            <Controller
                              render={props => (
                                <Select
                                  {...props}
                                  size="small"
                                  hasSearchBar={false}
                                  options={pageOptions}
                                  setValue={setValue}
                                  defaultValue={isFirstMount ? props.defaultValue : 5}
                                  onChange={value => {
                                    props.onChange(value);
                                    setPageNumber(1);
                                    getItensPerPage();
                                  }}
                                  ref={null}
                                />
                              )}
                              name={'pageItens'}
                              control={control}
                              defaultValue={pageOptions[0].value}
                            />
                          </FieldRow>
                          <h4>Total de {occurrenceNumber} itens</h4>
                        </LeftContainerFooter>
                        <RightContainerFooter>
                          <Paginate
                            previousLabel={
                              <svg
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 1L1 5L5 9"
                                  stroke="#AAAAAA"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 1L5 5L1 9"
                                  stroke="#AAAAAA"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            }
                            pageCount={pageCount1}
                            onPageChange={changePage}
                            breakLabel={'...'}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={4}
                          />
                        </RightContainerFooter>
                      </StyledContainerFooter>
                    </>
                  )}
                </>
              )}
              {!isDesktop && (
                <>
                  <StyledContainerHeader>
                    <TableTitle>Histórico de Chamados</TableTitle>
                    <OpenCallButton>
                      <PlusIcon />
                      ABRIR UM CHAMADO
                    </OpenCallButton>
                    <FiltersButton onClick={() => setFilterIsOpen(!filterIsOpen)}>
                      {filterIsOpen === true ? <CloseFilterIcon /> : <FilterIcon />}
                      FILTROS
                    </FiltersButton>
                  </StyledContainerHeader>
                  <FieldRow marginBottom="1rem">
                    <StyledInnerGridColumn size={2} columnGap="1rem">
                      {filterIsOpen && (
                        <MobileCleanFilterDiv>
                          <CleanFiltersButton onClick={cleanFilters}>
                            <CleanFilterIcon />
                            LIMPAR FILTROS
                          </CleanFiltersButton>
                        </MobileCleanFilterDiv>
                      )}
                      <StyledFormGroup label={'Data de Abertura'} isRequired width={'50%'} inputSize={'85%'}>
                        <Controller
                          render={props => (
                            <DateRangePicker
                              {...props}
                              data-cy="opening-of-occurrence-date-picker"
                              size="small"
                              notClean={true}
                              value={`${format(initialPeriod[0], 'yyyy-MM-dd')}/${format(
                                initialPeriod[1],
                                'yyyy-MM-dd',
                              )}`}
                              placeholder="Seleção obrigatória"
                              required={false}
                            />
                          )}
                          name="period"
                          control={control}
                          defaultValue={''}
                        />
                      </StyledFormGroup>
                      <SearchAndFilterContainer>
                        <StyledContainerSearchBar
                          placeholder={'Pesquisar Nº Chamado / Descrição'}
                          onChange={handleSearch}
                          ref={searchInputRef}
                        />
                      </SearchAndFilterContainer>
                    </StyledInnerGridColumn>
                    {filterIsOpen && (
                      <>
                        <StyledInnerGridColumn size={1}>
                          <StyledFormGroup label={'Unidade'} width={'283px'} inputSize={'100%'}>
                            <Controller
                              name="unidade"
                              control={control}
                              //defaultValue={fields?.companie?.cidade}
                              render={props => (
                                <Select
                                  {...props}
                                  size="small"
                                  inputDifferent={true}
                                  placeholder="Seleção Opcional"
                                  options={filters?.unidade}
                                  // onInputChange={handleChange}
                                  onChange={value => {
                                    //handleChange(value);
                                    props.onChange(value);
                                    fetchOccurNumber(debouncedSearchTerm);
                                  }}
                                  ref={null}
                                />
                              )}
                            />
                          </StyledFormGroup>
                          <StyledFormGroup label={'Ambiente'} width={'283px'} inputSize={'100%'}>
                            <Controller
                              name="ambiente"
                              control={control}
                              //defaultValue={fields?.companie?.cidade}
                              render={props => (
                                <Select
                                  {...props}
                                  size="small"
                                  inputDifferent={true}
                                  placeholder="Seleção Opcional"
                                  options={filters?.ambiente}
                                  // onInputChange={handleChange}
                                  onChange={value => {
                                    //handleChange(value);
                                    props.onChange(value);
                                    //fetchData();
                                    fetchOccurNumber(debouncedSearchTerm);
                                  }}
                                  ref={null}
                                />
                              )}
                            />
                          </StyledFormGroup>
                          <StyledFormGroup label={'Equipamento'} width={'283px'} inputSize={'100%'}>
                            <Controller
                              name="equipamento"
                              control={control}
                              //defaultValue={fields?.companie?.cidade}
                              render={props => (
                                <Select
                                  {...props}
                                  size="small"
                                  inputDifferent={true}
                                  placeholder="Seleção Opcional"
                                  options={filters?.equipamento}
                                  // onInputChange={handleChange}
                                  onChange={value => {
                                    //handleChange(value);
                                    props.onChange(value);
                                    //fetchData();
                                    fetchOccurNumber(debouncedSearchTerm);
                                  }}
                                  ref={null}
                                />
                              )}
                            />
                          </StyledFormGroup>
                          <StyledFormGroup label={'Status'} width={'200px'} inputSize={'100%'}>
                            <Controller
                              name="status"
                              control={control}
                              //defaultValue={fields?.companie?.cidade}
                              render={props => (
                                <Select
                                  {...props}
                                  size="small"
                                  inputDifferent={true}
                                  placeholder="Seleção Opcional"
                                  options={statusOptions}
                                  // onInputChange={handleChange}
                                  onChange={value => {
                                    //handleChange(value);
                                    props.onChange(value);
                                    fetchOccurNumber(debouncedSearchTerm);
                                    //fetchData();
                                  }}
                                  ref={null}
                                />
                              )}
                            />
                          </StyledFormGroup>
                        </StyledInnerGridColumn>
                        <StyledInnerGridColumn size={1}>
                          <RadioFormGroup label={'Exibir:'}>
                            <StyledFormRadio>
                              <Radio
                                text="Chamados que eu abri"
                                type="radio"
                                id="meusChamados"
                                name="chamadosType"
                                value="meus"
                                ref={register()}
                              />
                              <Radio
                                disabled={cookie?.chamadosAccess?.chamado}
                                text="Todos os chamados"
                                type="radio"
                                id="todosChamados"
                                name="chamadosType"
                                value="todos"
                                ref={register()}
                              />
                            </StyledFormRadio>
                          </RadioFormGroup>
                        </StyledInnerGridColumn>
                      </>
                    )}
                    <StyledInnerGridColumn>
                      <ReloadButton onClick={fetchData}>
                        <ReloadIcon />
                        ATUALIZAR LISTA
                      </ReloadButton>
                    </StyledInnerGridColumn>
                  </FieldRow>
                  {occurrenceNumber === 0 ? (
                    <NoOcurrenceScreen>
                      <NotFoundIcon />
                      <p>Nenhum chamado foi encontrado.</p>
                    </NoOcurrenceScreen>
                  ) : (
                    <>
                      <MobileCustomerPortalTable columns={columns} data={fields} loading={loading} />
                      <StyledContainerFooter>
                        <LeftContainerFooter>
                          <h3>Itens por página:</h3>
                          <FieldRow marginSides="16px">
                            <Controller
                              render={props => (
                                <Select
                                  {...props}
                                  size="small"
                                  hasSearchBar={false}
                                  options={pageOptions}
                                  setValue={setValue}
                                  defaultValue={isFirstMount ? props.defaultValue : 5}
                                  onChange={value => {
                                    props.onChange(value);
                                    setPageNumber(1);
                                    getItensPerPage();
                                  }}
                                  ref={null}
                                />
                              )}
                              name={'pageItens'}
                              control={control}
                              defaultValue={pageOptions[0].value}
                            />
                          </FieldRow>
                          {/* <h4>Total de {fields.length} itens</h4> */}
                        </LeftContainerFooter>
                        <RightContainerFooter>
                          <Paginate
                            previousLabel={
                              <svg
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 1L1 5L5 9"
                                  stroke="#AAAAAA"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 1L5 5L1 9"
                                  stroke="#AAAAAA"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            }
                            pageCount={pageCount1}
                            onPageChange={changePage}
                            breakLabel={'...'}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={4}
                          />
                        </RightContainerFooter>
                      </StyledContainerFooter>
                    </>
                  )}
                </>
              )}
            </TablecontainerWrapper>
          </>
        )}
        <FooterContainer>
          <LogoBlack />
          <p>Todos os direitos reservados.</p>
        </FooterContainer>
      </Content>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
    </Page>
  );
};
