import { DesktopMenu } from 'components/Menu/CustomerPortalMenu/DesktopMenu';
import { Header, TitleContainer, MenuContainer, Title, CompanyLogo } from './styled';
import { ReactComponent as TitleIcon } from 'assets/icons/customer-portal-title.svg';
import { MenuMobile } from 'components/Menu/CustomerPortalMenu/MobileMenu';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import { HeaderFilterService } from 'services/main';
import { useCookies } from 'react-cookie';
import { URLS } from 'constants/urls';
export const CustomerPortalHeader = ({ setOpenMyProfile, openMyProfile }) => {
  const isDesktop = useMediaQuery({ minWidth: 1081 });
  const [companies, setCompanies] = useState([]);
  const [, setCookie] = useCookies();
  const fetchCompanies = async () => {
    const response = HeaderFilterService.companies();
    const { data } = await response;
    const fields = data?.map(item => ({
      label: item?.name,
      value: item?.id,
      logo: item?.logoPath,
    }));
    setCompanies(fields);
    setCookie(
      'companies',
      {
        id: String(data[0]?.id),
        name: '',
      },
      { path: '/', domain: URLS.cookieDomain },
    );
  };
  useEffect(() => {
    fetchCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Header>
      <TitleContainer>
        <Title>
          <TitleIcon />
          <CompanyLogo src={process.env.REACT_APP_URL_IMAGES + `${companies?.logo}`} />
        </Title>
      </TitleContainer>
      <MenuContainer>
        {isDesktop === true ? (
          <DesktopMenu companies={companies} setOpenMyProfile={setOpenMyProfile} openMyProfile={openMyProfile} />
        ) : (
          <MenuMobile companies={companies} setOpenMyProfile={setOpenMyProfile} openMyProfile={openMyProfile} />
        )}
      </MenuContainer>
    </Header>
  );
};
