import { AxiosGlobalFilters, AxiosMediaCenter, AxiosMediaCenterSignature } from '../config';

export const RegistrationService = {
  getNewMediaPath: ({ mediaType, fileExtension, empresaId, token }) => {
    let url = `v1/storage/${empresaId}/newPath?type=${mediaType}`;

    if (fileExtension) {
      url += `&fileExtension=${fileExtension.replace('.', '')}`;
    }

    return AxiosMediaCenter({
      method: 'GET',
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  uploadFileToApi: ({ filePath, file, token }) => {
    const formData = new FormData();
    formData.append('files', file, filePath);
    return AxiosMediaCenterSignature({
      method: 'POST',
      url: 'v1/storage/upload',
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-type': 'multipart/form-data',
      },
    });
  },
  sendToHomepageEmpresa: ({ data, token }) => {
    return AxiosGlobalFilters({
      method: 'POST',
      url: '/v1/homepageempresa',
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  getCompanieInfo: ({ EmpresaId, token }) => {
    let url = `/v1/homepageempresa/${EmpresaId}`;
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
  getCity: ({ token, Nome }) => {
    let url = `/v1/Cidades?Nome=${Nome}`;
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getCompanyPageData: ({ subdominio }) => {
    let url = `v1/homepageempresa/${subdominio}`;
    return AxiosGlobalFilters({
      method: 'GET',
      url,
    });
  },
  getImagePath: ({ EmpresaId, usuarioId }) => {
    return AxiosGlobalFilters({
      method: 'PUT',
      url: 'v1/usuarios/signature',
      data: {
        usuarioId,
      },
      headers: {
        EmpresaId,
        'Content-Type': 'application/json',
      },
    });
  },
  getChamadoUserInfo: ({ EmpresaId, id }) => {
    let url = `v1/usuarios/${id}`;
    return AxiosGlobalFilters({
      method: 'GET',
      url,
      headers: {
        EmpresaId,
        'Content-Type': 'application/json',
      },
    });
  },
  saveChamadoUserInfo: ({ EmpresaId, nome, email, celular, forceEmailConfirmation }) => {
    return AxiosGlobalFilters({
      method: 'PUT',
      url: 'v1/usuarios',
      data: {
        nome,
        email,
        celular,
        forceEmailConfirmation,
      },
      headers: {
        EmpresaId,
        'Content-Type': 'application/json',
      },
    });
  },
  uploadSignature: ({ filePath, file, token }) => {
    const formData = new FormData();
    formData.append('files', file, filePath);
    return AxiosMediaCenterSignature({
      method: 'POST',
      url: 'v1/storage/upload',
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'multipart/form-data',
      },
    });
  },
};
