/* istanbul ignore file */

import { colors } from 'assets/styled/tokens';
import styled, { css } from 'styled-components';

const themes = {
  emailConfirmation: {
    color: 'white',
    background: colors.black,
  },
  info: {
    color: 'white',
    background: colors.darkGray,
  },
  success: {
    color: 'white',
    background: colors.successGreen,
  },
  darkSuccess: {
    color: 'white',
    background: colors.carbon,
  },
  warning: {
    color: 'white',
    background: colors.primary,
  },
  error: {
    color: 'white',
    background: colors.bittersweet,
  },
  trash: {
    color: 'white',
    background: colors.carbon,
  },
};

export const StyledToastContainer = styled.div`
  position: fixed;
  z-index: 999999;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const StyledToast = styled.div`
  transition: 0.6s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 16px 19px;
  width: 100%;
  max-height: 48px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: #000;
  opacity: 0.9;

  bottom: 10px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-bottom 0.7s;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.theme
      ? themes[props.theme]
        ? css`
            border-color: ${themes[props.theme].border};
            background-color: ${themes[props.theme].background};
            color: ${themes[props.theme].color};
          `
        : css`
            border-color: ${themes['info'].border};
            background-color: ${themes['info'].background};
            color: ${themes['info'].color};
          `
      : css`
          border-color: ${themes['info'].border};
          background-color: ${themes['info'].background};
          color: ${themes['info'].color};
        `}
`;

export const StyledButton = styled.button`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 5px 8px;
  border: 1px solid ${colors.white};
  border-radius: 3px;
  display: inline-flex;

  &:hover {
    background: ${colors.white};
    color: ${colors.darkGray};
  }
`;

export const StyledIconAction = styled.img`
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 12px;
`;

export const StyledCloseBtn = styled.img`
  width: 20px;
  height: 20px;
  padding: 4px;
  margin-left: 16px;
  display: inline-block;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const StyledToastColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    margin-right: 32px;
  }
`;

export const StyledToastSeparator = styled.div`
  flex: 0.5;
`;
